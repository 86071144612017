export const getSocialNetworkShareLink = (
  socialNetworkName: string,
  shareUrl: string,
  title?: string,
  imageId?: string,
) => {
  const channelParam =
    (shareUrl.includes("?") ? "&" : "?") + `channel=${socialNetworkName}`;

  switch (socialNetworkName) {
    case "facebook": {
      return `https://facebook.com/sharer/sharer.php?u=${`${encodeURIComponent(
        shareUrl + channelParam,
      )}`}`;
    }

    case "twitter": {
      return `https://twitter.com/share?url=${`${encodeURIComponent(
        shareUrl + channelParam,
      )}`}`;
    }

    case "whatsapp": {
      return `https:///wa.me/?text=${`${encodeURIComponent(
        shareUrl + channelParam,
      )}`}`;
    }

    case "pinterest": {
      return `https://in.pinterest.com/pin/create/button/?url=${`${encodeURIComponent(
        shareUrl + channelParam,
      )}`}&description=${title || ""}${
        imageId ? `&media=${imageId}&width=1440` : ""
      }`;
    }

    case "linkedin": {
      return `https://linkedin.com/sharing/share-offsite/?url=${`${encodeURIComponent(
        shareUrl + channelParam,
      )}`}`;
    }

    default: {
      return "";
    }
  }
};
